<template>
  <div>
   <base-header class="pb-1">
       <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>

   <div class="container-fluid mt--6">
    <form>
        <div class="col-md-12">
                <card>
                <h3 slot="header" class="mb-0">Relatório de Lead</h3>
                <table class="table table-responsive font-tabela-peq">
                    <thead class="thead">
                        <tr>
                            <th scope="col">Nome</th>
                            <th scope="col">CPF/CNPJ</th>
                            <th scope="col">Celular</th>
                            <th scope="col">E-mail</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="report in reportResponse" :key="report.holderId">
                            <td>{{report.name}}</td>
                            <td>{{report.cpfCnpj}}</td>
                            <td>{{report.cellphone}}</td>
                            <td>{{report.email}}</td>
                            <td>
                                <a href="#" title="Registrar Contato (CallCenter)" @click.prevent="newContact(report.holderId)"><i class="fas fa-headset ml-2"></i></a>
                            </td>
                        </tr>
                        <tr v-show="reportResponse.length == 0">
                            <td colspan="4">
                               <center> Nenhuma informação disponível no relatório.</center>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </card> 
             </div>   
        </form>   
   </div> <!--- fecha conteudo -->

     <modal :show.sync="showModalContact">
           <h4 class="text-center">Callcenter - Registro de Ligação</h4>
           <div class="col-md-4 col-md-8 font-pequena">
               <span>Titular:</span> <span class="ml-1">{{holderData.name}}</span>
           </div>  
           <div class="col-md-12 col-md-12 font-pequena">
               <span>Tel Residencial:</span> <span class="ml-1">{{holderData.homePhone}}</span>
               <span class="ml-3">Tel Celular:</span> <span class="ml-1">{{holderData.cellPhone}}</span>
           </div> 
           <div class="col-md-2 col-md-10 font-pequena">
               <span>E-mail:</span> <span class="ml-1">{{holderData.email}}</span>
           </div> 
           <div class="col-md-12 mt-2 text-center">
               <textarea rows="5" v-model="contactForm.description" style="width:100%;" class="form-control" placeholder="Registre as informações do contato com cliente"></textarea><br>
               <h4 v-if="protocolo != null"> Protocolo: {{protocolo}}</h4><br>
               <base-button type="primary" :disabled="beginSaveProcess" @click="saveContact">Registrar Contato</base-button>
           </div>
           <div class="col-md-12 text-center mt-4 font-pequena" v-if="contactHolderList.length == 0"><strong>Não existem registros de ligações para esse titular.</strong></div>
           <div class="row font-pequena scroll-ligacoes mt-2" v-if="contactHolderList.length > 0">
               <div class="col-md-12 font-pequena" v-for="contact in contactHolderList" :key="contact.id">
                    <hr>
                    <span class="font-pequena">Protocolo: <strong>{{contact.id}}</strong></span><br>
                    <span class="font-pequena">Data/Hora: <strong>{{contact.dateTimeContact}}</strong></span><br>
                    <span class="font-pequena">Operador: <strong>{{contact.user.name}}</strong></span><br>
                    <span class="font-pequena">Registro: <strong>{{contact.description}}</strong> </span>
               </div> 
           </div>     
              
     </modal>      
  </div>
</template>
<script>
export default {
  mounted(){
      this.loadReport()
  },
  data() {
    return {
        protocolo : null,
        beginSaveProcess: false,
        showModalContact : false,
        contactForm: {
            description: null,
            holder : {
                id : null
            }
        },
        reportResponse : [],
        contactHolderList: [],
        holderData: {
            name: null,
            email: null,
            homePhone: null,
            cellPhone: null
        }
    }
  },
  methods: {
      newContact(holderId){
        this.protocolo = null
        this.contactForm.description = null
        this.$clubApi.get('/holder/'+holderId)
        .then((response) => {
           this.holderData = response.data.object
           this.contactForm.holder.id = holderId
        }) .catch((error) => {
           this.$notify({type: 'warning', message: error.response.data.msg})
        }).finally(() =>{
            NProgress.done() 
        })
        this.$clubApi.get('/contact-holder/holder/'+holderId)
        .then((response) => {
            this.contactHolderList = response.data.object
        }) .catch((error) => {
            this.$notify({type: 'warning', message: error.response.data.msg})
        }).finally(() =>{
            NProgress.done() 
        })
        this.showModalContact = true
      },
      saveContact(){
          this.beginSaveProcess = true
          this.$clubApi.put('/contact-holder', this.contactForm)
            .then((response) => {
                this.newContact(response.data.object.holder.id)//reload 
                this.$notify({type: 'success', message: 'Registro efetuado!'})
                this.protocolo = response.data.object.id
            }) .catch((error) => {
                this.$notify({type: 'warning', message: error.response.data.msg})
            }).finally(() =>{
                this.beginSaveProcess = false
                NProgress.done() 
            })
      },
      loadReport(){
        this.$clubApi.get('/report/lead')
        .then((response) => {
            this.reportResponse = response.data.object
        }) .catch((error) => {
           this.$notify({type: 'warning', message: error.response.data.msg})
        }).finally(() =>{
            NProgress.done() 
        })
      }
  }
};
</script>

<style>
.font-pequena{
    font-size: 12px;
}
.scroll-ligacoes{
    overflow-y: scroll;
    overflow-x: none;
    height: 300px;
}
</style>